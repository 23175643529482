
import React from 'react';
//import bg from '../assets/images/pexels-ksenia-chernaya-3965543HD.jpg';
import icl from '../assets/images/technology-help-teachers.jpg';


import Article from '../components/Article';
import SubPageStyles from '../styles/SubPageStyles';

export default function AuditPage() {
  return (
    <SubPageStyles >
        <Article title="Auditoría">

        <br />
        <h3>La importancia de las auditorías</h3>
        <br />

        <h4>¿Es usted de las personas que piensa, que hay que prepararse muy bien, solamente para pasar las auditorías que le hacen los organismos certificadores o sus clientes?</h4>
        
        <p>Las auditorías a los sistemas de gestión son muy importantes porque son la herramienta con la que usted puede asegurarse de que, las actividades que soportan los procesos en su empresa, le están apoyando en su estrategia organizacional de una manera eficaz y eficiente.</p>

        <p>El concepto tradicional que le hace pensar en las auditorías únicamente, como un requisito de la norma que usted aplica en su empresa, es un pensamiento que subsiste anclado en una realidad del siglo pasado y que al día de hoy no es vigente. Las auditorías tampoco deben pensarse como un medio para obtener una certificación que, simplemente, puede mostrar a sus visitantes cuando observen las paredes de la recepción mientras son atendidos.  </p>

           <img src={icl}>
           </img>
        <p>Aunque, En las normas ISO  9001 (2015), 14001 (2015), 45001 (2018) y 22000 (2018); 
         , se hace énfasis en el nuevo enfoque de las auditorías para atender las necesidades organizacionales que requieren un mejoramiento, este enfoque tiene una trayectoria de más de 20 años que no ha sido sacado a la luz pública en la normalización ISO sino hasta los últimos tres años del presente siglo. Esto quiere decir, que debemos mantener la sana práctica de realizar auditorías internas para darle tratamiento a las necesidades de mejoramiento en nuestra empresa y así, poder darle un impulso a la estrategia organizacional.</p>
        
        <p>Con un enfoque similar, también debemos tratar la revisión por la dirección, con el fin de que no sea hecha solo una vez al año, sino que pueda entrar en fusión, con la revisión del negocio que se hace con frecuencias mensuales o bimensuales. De nuevo, no es solo por cumplir un requisito de la norma, que debemos hacer la revisión por la dirección, sino que esta debe ser entendida como la misma revisión del negocio, ya que los temas a tratar son completamente compatibles y pueden ser revisados en frecuencias diferentes según sea requerido.</p>

        <p>El enfoque desde los sistemas de gestión y su auditoría para apoyar la estrategia organizacional es lo que <b>Fernando Sandoval Arenas-Coaching y Consultoría</b> le brinda, para que en su organización, haya una consciencia sobre la importancia de las mismas, convirtiéndolas en una filosofía de trabajo del día a día, sin diferenciarlas, de las actividades propias y naturales del negocio.    </p>
        <br />
           
        </Article>
    </SubPageStyles>
  );
}
